import React from "react";
import ServiceSidebar from "./ServiceSidebar";
import project6 from "../../assets/images/projects/project6.jpg";
import { useTranslation } from "gatsby-plugin-react-i18next";

const MI = () => {
	const { t } = useTranslation();

	return (
		<section className="services-details-area pt-100px ptb-100">
			<div className="container">
				<div className="row">
					<div className="col-lg-8 col-md-12">
						<div className="services-details-desc">
							<span className="sub-title">
								<h2>{t("home.MIh2")}</h2>
							</span>
							<h3>{t("home.MIh31")}</h3>
							<p>{t("home.MIp1")}</p>
							<p>{t("home.MIp2")}</p>
							<div className="row align-items-center">
								<div className="col-lg-6 col-md-6">
									<div className="image">
										<img src={project6} alt="about" />
									</div>
								</div>

								<div className="col-lg-6 col-md-6">
									<h3>{t("home.MIh32")}</h3>
									<p>{t("home.MIp3")}</p>
									<p>{t("home.MIp4")}</p>
									<div className="content">
										<span className="sub-title">
											<h3>{t("home.MIh33")}</h3>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="col-lg-4 col-md-12">
						<ServiceSidebar />
					</div>
				</div>
			</div>
		</section>
	);
};

export default MI;
